import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Gallery.css';

import food1 from '../images/food/food1.JPEG';
import food2 from '../images/food/food2.JPEG';
import food3 from '../images/food/food3.JPEG';
import food4 from '../images/food/food4.JPEG';
import foodvid from '../images/food/foodvid.mp4';
import play1 from '../images/hor/hor5.jpg';
import play2 from '../images/hor/hor6.jpg';
import play3 from '../images/hor/hor7.jpg';
import play4 from '../images/hor/hor4.jpg';
import play5 from '../images/hor/hor11.JPEG';
import play6 from '../images/hor/hor4.jpg';
import play7 from '../images/hor/hor1.jpg';
import playvid from '../images/hor/playvid.MP4';
import activity1 from '../images/hor/hor8.jpg';
import activity2 from '../images/hor/hor9.jpg';
import activity3 from '../images/hor/hor15.jpg';
import activity4 from '../images/hor/hor17.jpg';
import activity5 from '../images/hor/hor19.JPEG';
import activity6 from '../images/hor/hor20.JPEG';
import activity7 from '../images/hor/hor21.JPEG';
import activity8 from '../images/hor/hor22.JPEG';
import actvid1 from '../images/hor/actvid1.mp4';
import actvid2 from '../images/hor/actvid2.mp4';

const GallerySection = ({ title, description, images }) => {
  const settings = {
    centerMode: true,
    centerPadding: '22%',
    slidesToShow: 1,
    infinite: true,
    speed: 500,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '15%',
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '10%',
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-4">{title}</h2>
      <p className="mb-4">{description}</p>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-2">
            {typeof image === 'string' ? (
              <div className="image-container">
                <img src={image} alt={`${title} ${index + 1}`} className="slider-image rounded-lg" />
              </div>
            ) : (
              <div className="video-container">
                <video autoPlay loop muted playsInline className="slider-video">
                  <source src={image.src} type={image.type} />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

const Gallery = () => {
  const foodImages = [
    food1,
    food2,
    food3,
    food4,
    { src: foodvid, type: 'video/mp4' }
  ];
  const playImages = [play1, play2, play3, play4, play5, play6, play7,
    { src: playvid, type: 'video/mp4' }
  ];
  const activityImages = [activity1, activity2, activity3, activity4, activity5, activity6, activity7, activity8,
    { src: actvid1, type: 'video/mp4' },
    { src: actvid2, type: 'video/mp4' }
  ];
  return (
    <div className="container mx-auto p-4">
      <GallerySection
        title="Food & Nutrition "
        description="At our daycare, we prioritize the well-being and health of the children by providing high-quality meals. Our food program includes a variety of nutritious options, carefully planned to meet dietary guidelines and support the growth and development of young children. We source organic & fresh ingredients in order to prepare balanced meals that are both delicious and wholesome. Your child's health and happiness are our top priorities, and our commitment to quality food reflects this dedication."
        images={foodImages}
      />
      <GallerySection
        title="Kids at Play"
        description="At Sunflower Childcare Center we foster child development through play and exploration by providing a nurturing environment rich with diverse, stimulating activities. The daycare's approach emphasizes hands-on learning, allowing children to engage with a variety of toys, games, and creative materials that spark curiosity and imagination. Educators guide children through both structured and free-play activities, encouraging social interaction, problem-solving, and cognitive growth. This playful and explorative atmosphere helps children develop essential skills in a fun and natural way."
        images={playImages}
      />
      <GallerySection
        title="Activities"
        description="At Sunflower Childcare Center, arts and crafts activities are a cornerstone of our developmental approach, providing endless fun and creativity. Children delight in experimenting with colors, textures, and materials, whether they are painting, drawing, or building imaginative creations. These activities not only offer a joyful outlet for self-expression but also enhance fine motor skills, hand-eye coordination, and cognitive abilities. Through guided projects and freeform art, children learn to follow instructions, solve problems, and think creatively, all while having a wonderful time. This vibrant, artistic environment cultivates their development in an engaging and enjoyable manner."
        images={activityImages}
      />
    </div>
  );
};

export default Gallery;
