import React from 'react';

function Footer() {
  return (
    <footer className="bg-primary p-4 mt-8">
      <div className="container mx-auto text-center text-white">
        <p className="text-xl flex flex-col md:flex-row justify-center items-center">
          Call Us:  
          <button 
            className="bg-yellow-400 text-black rounded px-4 py-2 m-2 shadow-lg transform transition-transform duration-300 hover:scale-105 focus:outline-none"
            onClick={() => window.location.href = 'tel:4258945599'}
          >
            📞 (425) 894-5599
          </button> 
          Email Us:
          <button 
            className="bg-yellow-400 text-black rounded px-4 py-2 m-2 shadow-lg transform transition-transform duration-300 hover:scale-105 focus:outline-none"
            onClick={() => window.location.href = 'mailto:sunflowerchildcarebellevue@gmail.com'}
          >
            📩 sunflowerchildcarebellevue@gmail.com
          </button>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
