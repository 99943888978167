import React from 'react';
import BasicForm from '../components/BasicForm';

function Contact() {
  return (
    <div className="container mx-auto mt-8 p-4">
      <h1 className="text-4xl font-bold mb-4 underline underline-1 text-primary">Contact Us</h1>
      <div className="flex flex-wrap items-start">
        <div className="w-full sm:w-1/2 sm:pr-4">
          <div className="flex flex-col">
            <p className="text-lg mb-2">For bookings and inquiries, please reach us at:</p>
            <div className="flex items-center mb-4">
              <a
                href="tel:4258945599"
                className="bg-yellow-400 text-black rounded px-4 py-2 shadow-lg transform transition-transform duration-300 hover:scale-105 focus:outline-none"
              >
                📞 Call us at (425) 894-5599
              </a>
            </div>
            <div className="flex items-center mb-4">
              <a
                href="mailto:sunflowerchildcarebellevue@gmail.com"
                className="bg-yellow-400 text-black rounded px-4 py-2 shadow-lg transform transition-transform duration-300 hover:scale-105 focus:outline-none"
              >
                📩 Email Us
              </a>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2690.754033267312!2d-122.16886462411503!3d47.592026088984426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906d79b7bfc8c7%3A0x6ced197d907d00b9!2sSunflower%20Childcare%20Center!5e0!3m2!1sen!2sus!4v1718497335518!5m2!1sen!2sus&z=12"
              width="100%"
              height="300"
              frameBorder="0"
              style={{ border: 0, marginBottom: '1rem' }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            /> 
          </div>
        </div>
        <div className="w-full sm:w-1/2">
          <BasicForm />
        </div>
      </div>
    </div>
  );
}

export default Contact;
