import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../images/logo.png';

function Header() {
  return (
    <header className="bg-primary p-4 relative z-50" style={{ color: '#FFD900' }}>
      <div className="container mx-auto flex justify-between items-center flex-wrap">
        <NavLink to="/" className="flex items-center flex-grow sm:flex-grow-0 sm:justify-start justify-center">
          <img src={logo} alt="Logo" className="h-16 w-16 mr-2" />
          <span className="text-2xl font-bold" style={{color: 'white'}}>Sunflower Childcare Center</span>
        </NavLink>
        <nav className="w-full sm:w-auto text-bold flex flex-wrap justify-center sm:justify-end mt-0 sm:mt-0" style={{backgroundColor: 'transparent'}}>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? 'mx-2 my-1 font-bold underline ' : 'mx-2 my-1 '
            }
            style={{fontSize: '1.2rem'}}
          >
            Home
          </NavLink>
          <NavLink
            to="/gallery"
            className={({ isActive }) =>
              isActive ? 'mx-2 my-1 font-bold underline text-2xl' : 'mx-2 my-1 text-2xl'
            }
            style={{fontSize: '1.2rem'}}
          >
            Gallery
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? 'mx-2 my-1 font-bold underline text-xl' : 'mx-2 my-1 text-2xl'
            }
            style={{fontSize: '1.2rem'}}
          >
            Contact Us
          </NavLink>
        </nav>
      </div>
    </header>

  );
}

export default Header;
