import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaComment } from 'react-icons/fa';

const ChatButton = () => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate('/contact')}
      className="fixed bottom-4 right-4 bg-secondary text-white p-3 rounded-full shadow-lg focus:outline-none"
    >
        <FaComment size={24} />
    </button>
  );
};

export default ChatButton;
