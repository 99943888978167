import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './animations.css';
import ver1 from '../images/ver/ver1.JPEG';
import ver2 from '../images/ver/ver2.JPEG';
import ver3 from '../images/ver/ver3.JPEG';
import ver4 from '../images/ver/ver4.JPEG';
import ver5 from '../images/ver/ver5.JPEG';
import ver6 from '../images/ver/ver6.JPEG';
import logo from '../images/logostem.png'; 

const images = [
  ver1,
  ver2,
  ver3,
  ver4,
  ver5,
  ver6,
];

function Logo() {
  return (
    <img src={logo} alt="Logo" className="w-40 h-auto mb-6" />
  );
}

function Home() {
  const aboutRef = useRef(null);
  const [hasRoundedCorners, setHasRoundedCorners] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (aboutRef.current) {
        const aboutPosition = aboutRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (aboutPosition < windowHeight * 0.75) {
          aboutRef.current.classList.add('animate-fade-in-up');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHasRoundedCorners(true);
    }, 0); 

    return () => clearTimeout(timer);
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
    touchThreshold: 10,
    autoplaySpeed: 3000,
  };

  return (
    <div>
      <div className="relative h-screen bg-cover bg-center animate-fade-in" style={{ backgroundImage: `url(${ver6})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 animate-fade-in-up">
          <h1 className="text-5xl md:text-6xl font-bold font-handlee mb-4">Welcome to <span className='text-yellow-400'  style={{textShadow: '0 0px 5px black', font: 'Handlee'}}> Sunflower </span> Childcare Center</h1>
        </div>
      </div>
      <div className="container mx-auto mt-8 p-4 flex flex-wrap">
        <div className="w-full lg:w-1/2 order-2 lg:order-1">
          <Slider {...sliderSettings}>
            {images.map((image, ver) => (
              <div key={ver} className="p-2">
                <img
                  src={image}
                  alt={`Gallery ${ver + 1}`}
                  className={`max-h-400 w-auto shadow-lg transition-all duration-1000 ${hasRoundedCorners ? 'rounded-lg' : ''}`}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="w-full lg:w-1/2 order-1 lg:order-2 flex flex-col justify-start p-4" ref={aboutRef}>
          <h1 className="text-4xl font-bold font-pacifico mb-4">About Sunflower Childcare Center</h1>
          <div className="flex flex-wrap lg:flex-nowrap">
            <div className="lg:w-3/4">
              <p className="text-lg">
                Sunflower Childcare Center is dedicated to providing a safe, nurturing, and stimulating environment for children. Our experienced staff is committed to fostering each child's development and growth through engaging activities and personalized care.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
