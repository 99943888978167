import React, { useState } from 'react';

const BasicForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    fetch("https://formcarry.com/s/8rJvdZTunAJ", {
      method: 'POST',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ name, email, message })
    })
    .then(response => response.json())
    .then(response => {
      if (response.code === 200) {
        setName('');
        setEmail('');
        setMessage('');
        setError('');
        alert('Your message has been sent successfully!');
      } else if (response.code === 422) {
        setError(response.message);
      } else {
        setError(response.message ? response.message : 'Unknown error');
      }
    })
    .catch(error => {
      setError(error.message ? error.message : 'Network error');
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="name">Full Name</label>
        <input
          type="text"
          id="name"
          placeholder="Your first and last name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="email">Your Email Address</label>
        <input
          type="email"
          id="email"
          placeholder="john@doe.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="message">Your Message</label>
        <textarea
          id="message"
          placeholder="Enter your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
      </div>

      {error && <p className="error-message">{error}</p>}

      <div className="form-group">
        <button type="submit" className='"bg-yellow-400 text-black rounded px-4 py-2 m- shadow-lg transform transition-transform duration-300 hover:scale-105 focus:outline-none'>Send</button>
      </div>
    </form>
  );
};

export default BasicForm;
